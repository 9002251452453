export default {
    nav: {
        main: {
            assets: 'Master data',
            dafConnect: 'DAF Connect',
        },
        assets: {
            users: 'Users',
            globalValues: 'Settings',
            globalFiles: 'Special files',
        },
        audit: {
            accessLog: 'Access Log',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        dafConnect: {
            source: 'Sources',
            rule: 'Rules',
        },
    },
    user: {
        overview: {
            title: 'Users',
            addButton: 'Add User',
        },
        create: {
            title: 'Create User',
        },
        edit: {
            title: 'Edit User',
        },
        field: {
            fullName: { label: 'Name' },
            firstName: { label: 'First Name' },
            lastName: { label: 'Last Name' },
            email: { label: 'Email' },
            password: { label: 'Password' },
            dateJoined: { label: 'Added At' },
            groups: {
                label: 'Groups',
                value: {
                    superuser: 'Superuser',
                    admin: 'Admin',
                    manager: 'Manager',
                },
                empty: 'No groups assigned',
            },
            language: {
                label: 'Language'
            },
            isActive: {
                label: 'Users',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Not Active',
                }
            },
        },
        login: {
            title: 'Login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}',
            },
            loginButton: 'Log In',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send Email',
            requestedEmailText:
                'An email with a reset code has been sent to this email address.',
            loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
            loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
        },
        changePassword: {
            title: 'Change Password',
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Save',
        },
        account: {
            title: 'Account Details',
            logoutButton: 'Log Out',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Change Password',
            },
            logout: {
                title: 'Log Out',
                confirm: 'Are you sure you want to logout?'
            },
        },
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset Default',
            setDefault: 'Set Default',
            setCurrent: 'Save Current Filters',
            delete: 'Delete',
        },
    },
    globalValue: {
        edit: {
            title: 'Edit value of global setting',
        },
        overview: {
            title: 'Global settings',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Value',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Edit special file',
        },
        overview: {
            title: 'Special files',
        },
        field: {
            key: {
                label: 'Name for file',
            },
            value: {
                label: 'File',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Audit log',
        },
        field: {
            originalUser: {
                label: 'Original user',
            },
            masqueradingAsUser: {
                label: 'Masquerading as user',
            },
            ipAddress: {
                label: 'IP address',
            },
            method: {
                label: 'HTTP method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status code',
            },
            additionalInfo: {
                label: 'Extra information',
            },
            requestTime: {
                label: 'Time of access',
            },
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Edit',
        delete: 'Delete',
        view: 'View',
        restore:'Restore',
    },
    form: {
        repeat: 'Repeat',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Other',
            none: 'No file uploaded yet',
        },
        saveButton: 'Save',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            newAppVersion:
                'New version available, click to reload page as soon as possible',
        },
        submitButton: 'Submit',
        cancelButton: 'Cancel',
        addButton: 'Add',
        startDate: 'Start Date',
        endDate: 'End Date',
        deleteConfirmation: 'Are you sure you want to delete this entity?',
        restoreConfirmation: 'Are you sure you want to restore this entity?'
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: 'We’re very sorry, but the application has crashed.',
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{weekNumber}}',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Page not found',
    brandingMessage: 'Powered by Code Yellow',
    dafConnectSource: {
        add: { title: 'Add DAF Connect Source' },
        edit: { title: 'Edit DAF Connect Source' },
        overview: { title: 'DAF Connect Sources' },
        field: {
            id: { label: 'ID' },
            name: { label: 'Name' },
            baseUrl: { label: 'Base URL' },
            username: { label: 'Username' },
            password: { label: 'Password' },
            verifiedAt: { label: 'Verified At' },
        }
    },
    rule: {
        overview: { title: 'Rules' },
        edit: { title: 'Edit Rule' },
        create: { title: 'Add Rule' },
        field: {
            id: { label: 'ID' },
            name: { label: 'Name' },
            description: { label: 'Description' },
            orderBy: { label: 'Order By' },
            filter: { label: 'Filter' },
            check: { label: 'Check' },
            success: { label: 'Success' },
            fail: { label: 'Fail' },
        },
    },
    test: {
        field: {
            rowCount: {
                value_one: '({{count}} data point)',
                value_other: '({{count}} data points)',
            },
        },
    },
    vehicle: {
        field: {
            vin: { label: 'VIN' },
            name: { label: 'Name' },
            model: { label: 'Model' },
            chassisType: { label: 'Chassis' },
        },
    },
    vehicleTest: {
        field: {
            rowCount: { label: 'Data Points' },
        },
    },
    vehicleTestRow: {
        field: {
            result: { label: 'Result' },
            row: { label: 'Row' },
        },
    },
};
