import styled from 'styled-components';
import LoginImage from 'image/login_image.jpg';


export default styled.div`
    flex: 1;
    display: flex;

    position: relative;

    &:before {
        content: '';

        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% - 350px);
        height: 100%;

        background-image: url(${LoginImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
`;
